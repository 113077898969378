.App {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100vw;
  overflow: hidden;
}
.main {
  flex: 1;
}
#video-background {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.button-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

button {
  border: none;
  background: transparent;
  cursor: pointer;
}
button img{
  width: 20px;
}
