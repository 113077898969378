.footer {
  margin-top: auto;
  background-color: transparent;
  color: #fff;
  padding: 1rem;
  text-align: center;
}
.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}
.social {
  display: flex;
  align-items: center;
  margin-left: 70px;
}
.social img {
  margin-right: 15px;
  width: 20px;
  cursor: pointer;
}
.social .facebook {
  width: 10px;
}

.contact {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 40px;
}

.hidden {
  text-decoration: none;
  font-size: 15px;
  display: none;
  top: 100%;
  left: 0;
  color: white;
  padding: 5px;
}

.contact:hover .hidden {
  display: block;
}

@media (max-width: 756px) {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0px;
  }

  .hidden {
    text-decoration: none;
    font-size: 15px;
    display: none;
    top: 100%;
    left: 0;
    color: white;
    padding: 5px;
  }

  .contact:hover .hidden {
    display: block;
  }
  .social {
    margin-top: 1rem;
    margin-right: 55px;
  }
  .social img {
    margin-right: 15px;
    margin-bottom: 15px;
  }
  .social .facebook {
    width: 10px;
  }
}
