body {
  font-family: "Pontano Sans", sans-serif;
  font-size: 14px;
  letter-spacing: 4px;
  background-color: white;
}

* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}
