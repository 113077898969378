.navbar {
  display: flex;
  justify-content: space-between;

  margin: 20px;
}
.navbar img {
  width: 160px;
  margin: 20px;
  margin-top: 15px;
  margin-left: 50px;
}

.navbar_menu a {
  text-decoration: none;
  margin-right: 20px;
  font-size: larger;
  color: #fff;
}

.navbar_menu a:hover {
  text-decoration: line-through;
}

.hamburger {
  display: none;
}

/* mobile code */
@media (max-width: 768px) {
  .navbar {
    display: flex;
    justify-content: space-between;
    margin: 0px;
  }
  .navbar_menu {
    display: none;
    margin-right: 20px;
    font-size: larger;
    color: #fff;
    position: relative;
  }
  .navbar_menu_open {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 999;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
  .hamburger {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0px;
    margin-right: -25px;
    appearance: none;
    outline: none;
    position: absolute;
    top: 70px; /* add this line */
    right: 70px; /* add this line */
    z-index: 9999;
  }
  .hamburger_line {
    display: block;
    width: 60px;
    height: 3px;
    background: #fff;
    margin: 5px auto;
    transition: transform 0.3s ease-in-out;
  }
  .hamburger_line:nth-child(1) {
    width: 40px;
    margin-left: 50px;
  }
  .hamburger_line:nth-child(2) {
    width: 60px;
    margin-left: 30px;
  }
  .hamburger_line:nth-child(3) {
    width: 80px;
    margin-left: 10px;
  }

  .hamburger_line_open:nth-child(1) {
    transform: translateY(4px) rotate(45deg);
  }
  .hamburger_line_open:nth-child(2) {
    opacity: 0;
  }

  .hamburger_line_open:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
}
