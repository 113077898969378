.merch {
  background: black;
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100vw;
  overflow: hidden;
}

.comingsoon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

@media (max-width: 756px) {
  .comingsoon img {
    width: 300px;
  }
}
